import { navigate } from '@reach/router';
import React, { useEffect } from 'react'

const FIPP = () => {
  useEffect(() => {
    navigate('https://insights.vericode.com.br/programa-estagio-fipp');
  }, [])

  return (
    <div className='flex text-white justify-center h-screen items-center -mt-16'>Carregando...</div>
  )
}

export default FIPP